import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  display: grid;
  grid-gap: 25px 25px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 25px;
  }
`

const CardList = props => {
  return <List>{props.children}</List>
}

export default CardList
