import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
dayjs.extend(relativeTime)

const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
`

const Title = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: calc(${props => props.theme.typography.headlineMedium.lhSm} * 3);

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: 3px;
    max-height: calc(
      ${props => props.theme.typography.headlineMedium.lhLg} * 3
    );
  }
`

const Post = styled.li`
  padding-left: 10px;
  padding-right: 10px;

  > a {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    background-color: white;
    box-shadow: ${props => props.theme.shared.boxShadow};
    text-decoration: none;
    color: inherit;
  }

  .headline--secondary {
    margin-top: 6px;
    margin-bottom: 12px;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    grid-column: span 4;

    .gatsby-image-wrapper {
      width: 100%;
      height: 230px;
    }
  }
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  category,
  body,
  ...props
}) => {
  const relativePublishTime = dayjs(publishDate).fromNow()
  return (
    <Post className={props.index === 0 ? 'post-one' : ''}>
      <Link to={`/news/${slug}/`}>
        <Img
          alt={`${title} Post Feature Image`}
          fluid={heroImage.fluid}
          backgroundColor={'#eeeeee'}
        />
        <DetailsWrapper>
          <p className="label">{category.title}</p>
          <Title className="headline--medium" title={title}>
            {title}
          </Title>
          <p className="detail">
            {relativePublishTime.match(/second|minute/g)
              ? 'Just now'
              : relativePublishTime}
          </p>
        </DetailsWrapper>
      </Link>
    </Post>
  )
}

export default Card
